<template>
  <div :style="{ fontSize: size, marginBottom: bottom }" class="title">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'AdminTitle',
  props: {
    size: {
      type: String,
      default: '15px'
    },
    title: {
      type: String,
      default: ''
    },
    bottom: {
      type: String,
      default: '20px'
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 400;
  // font-size: 15px;
  // margin-bottom: 20px;
}

.title::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 4px;
  height: 12px;
  background-color: #00aeff;
}
</style>
