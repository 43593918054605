const systemManageRouter = {
  path: '/systemManage',
  name: 'SystemManage',
  redirect: '/systemManage/dictManage/list',
  component: () => import('@/views/systemManage/index.vue'),
  meta: { name: '系统设置', icon: 'el-icon-setting' },
  children: [
    // {
    //   path: '/systemManage/city/list',
    //   name: 'CityList',
    //   component: () => import('@/views/systemManage/city/list.vue'),
    //   meta: { name: '城市设置' }
    // },
    // {
    //   path: '/systemManage/city/add',
    //   name: 'CityAdd',
    //   component: () => import('@/views/systemManage/city/addOrEdit.vue'),
    //   meta: { name: '新增城市', hidden: true, activeMenu: '/systemManage/city/list' }
    // },
    // {
    //   path: '/systemManage/city/edit/:id?',
    //   name: 'CityEdit',
    //   component: () => import('@/views/systemManage/city/addOrEdit.vue'),
    //   meta: { name: '编辑城市', hidden: true, activeMenu: '/systemManage/city/list' }
    // },
    // {
    //   path: '/systemManage/dictManage/list',
    //   name: 'DictManageList',
    //   component: () => import('@/views/systemManage/dictManage/index.vue'),
    //   meta: { name: '字典管理' }
    // },

    // {
    //   path: '/systemManage/dictValueManage',
    //   name: 'DictValueManage',
    //   component: () => import('@/views/systemManage/dictManage/list-dict-value.vue'),
    //   meta: { hidden: true, name: '字典值管理' }
    // },

    {
      path: '/systemManage/config/list',
      name: 'ConfigList',
      component: () => import('@/views/systemManage/config/list.vue'),
      meta: { name: '网站设置' }
    },
    // {
    //   path: '/systemManage/banner/list',
    //   name: 'BannerList',
    //   component: () => import('@/views/systemManage/banner/list.vue'),
    //   meta: { name: 'banner设置' }
    // },
    {
      path: '/systemManage/kefu/list',
      name: 'KefuList',
      component: () => import('@/views/systemManage/kefu/list.vue'),
      meta: { name: '客服设置' }
    },
    // {
    //   path: '/systemManage/banner/add',
    //   name: 'BannerAdd',
    //   component: () => import('@/views/systemManage/banner/addEdit.vue'),
    //   meta: { name: '新增banner', hidden: true, activeMenu: '/systemManage/banner/list' }
    // },
    // {
    //   path: '/systemManage/banner/edit/:id?',
    //   name: 'BannerEdit',
    //   component: () => import('@/views/systemManage/banner/addEdit.vue'),
    //   meta: { name: '编辑banner', hidden: true, activeMenu: '/systemManage/banner/list' }
    // },
    {
      path: '/systemManage/agreement/list',
      name: 'AgreementList',
      component: () => import('@/views/systemManage/agreement/list.vue'),
      meta: { name: '协议设置' }
    }
    // {
    //   path: '/systemManage/share/list',
    //   name: 'shareList',
    //   component: () => import('@/views/systemManage/share/list.vue'),
    //   meta: { name: '分享设置' }
    // },
    // {
    //   path: '/systemManage/mini/settings',
    //   name: 'MiniSettings',
    //   component: () => import('@/views/systemManage/mini-setting/list.vue'),
    //   meta: { name: '小程序设置' }
    // }
    // {
    //   path: '/systemManage/notice/list',
    //   name: 'NoticeList',
    //   component: () => import('@/views/systemManage/notice/index.vue'),
    //   meta: { name: '通知公告' }
    // },
    // {
    //   path: '/systemManage/notice/add',
    //   name: 'NoticeAdd',
    //   component: () => import('@/views/systemManage/notice/addEdit.vue'),
    //   meta: { name: '新增', hidden: true, activeMenu: '/systemManage/notice/list' }
    // },
    // {
    //   path: '/systemManage/notice/edit/:id?',
    //   name: 'NoticeEdit',
    //   component: () => import('@/views/systemManage/notice/addEdit.vue'),
    //   meta: { name: '编辑', hidden: true, activeMenu: '/systemManage/notice/list' }
    // }
  ]
}

export default systemManageRouter
