// 常量路由

import systemManageRouter from '@/router/modules/systemManage'

export const constantRoutes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-s-home',
      name: '首页'
    },
    component: () => import('../views/home/index.vue')
  }
]

// 任意路由
export const anyRoutes = [
  //
]

// 动态路由
export const asyncRoutes = () => [
  // 文章管理
  {
    path: '/article',
    name: 'Article',
    component: () => import('@/views/article/index.vue'),
    meta: { name: '文章管理', icon: 'el-icon-document' },
    children: [
      {
        path: '/article/problem/list',
        name: 'ProblemList',
        component: () => import('@/views/article/problem/list.vue'),
        meta: { name: '常见问题' }
      },
      {
        path: '/article/travel/list',
        name: 'TravelList',
        component: () => import('@/views/article/travel/list.vue'),
        meta: { name: '旅游攻略' }
      }
    ]
  },
  {
    path: '/marketing',
    name: 'Marketing',
    component: () => import('@/views/marketing/index.vue'),
    meta: { name: '营销管理', icon: 'el-icon-bangzhu' },
    children: [
      {
        path: '/marketing/coupon/list',
        name: 'CouponList',
        component: () => import('@/views/marketing/coupon/list.vue'),
        meta: { name: '优惠券列表' }
      },
      {
        path: '/marketing/shareActivity/list',
        name: 'ShareActivityList',
        component: () => import('@/views/marketing/shareActivity/list.vue'),
        meta: { name: '分享活动' }
      }
    ]
  },

  // 订单管理
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/index.vue'),
    meta: { name: '订单管理', icon: 'el-icon-s-order' },
    children: [
      {
        path: '/order/order-list/list',
        name: 'OrderList',
        component: () => import('@/views/order/order-list/list.vue'),
        meta: { name: '寄存订单', keepAlive: true }
      },
      {
        path: '/order/comment/list',
        name: 'OrderCommentList',
        component: () => import('@/views/order/comment-list/list.vue'),
        meta: { name: '订单评价' }
      },
      {
        path: '/order/withdraw-list/list',
        name: 'WithdrawList',
        component: () => import('@/views/order/withdraw-list/list.vue'),
        meta: { name: '退款订单' }
      }
    ]
  },

  // 用户管理
  {
    path: '/customer',
    name: 'Cusomer',
    component: () => import('@/views/customer/index.vue'),
    meta: { name: '用户管理', icon: 'el-icon-user-solid' },
    children: [
      {
        path: '/customer/customer-list/list',
        name: 'CustomerList',
        component: () => import('@/views/customer/customer-list/list.vue'),
        meta: { name: '用户列表', keepAlive: true }
      },
      {
        path: '/customer/invoice-list/list',
        name: 'InvoiceList',
        component: () => import('@/views/customer/invoice-list/list.vue'),
        meta: { name: '发票列表', keepAlive: true }
      }
      // {
      //   path: '/customer/vip-set/list',
      //   name: 'VipSetList',
      //   component: () => import('@/views/customer/vip-set-list/list.vue'),
      //   meta: { name: '会员体系', keepAlive: true }
      // },
      // {
      //   path: '/customer/review/list',
      //   name: 'CustomerReview',
      //   component: () => import('@/views/customer/review-list/list.vue'),
      //   meta: { name: '资料审核', keepAlive: true }
      // }
      // {
      //   path: '/customer/bill/list',
      //   name: 'CustomerBill',
      //   component: () => import('@/views/customer/customer-bill/list.vue'),
      //   meta: { name: '用户流水', keepAlive: true }
      // }
    ]
  },

  // 商户管理
  {
    path: '/merchant',
    name: 'Merchant',
    component: () => import('@/views/merchant/index.vue'),
    meta: { name: '商户管理', icon: 'el-icon-school' },
    children: [
      {
        path: '/merchant/merchant-list/list',
        name: 'MerchantList',
        component: () => import('@/views/merchant/merchant-list/list.vue'),
        meta: { name: '商户列表', keepAlive: true }
      },
      {
        path: '/merchant/apply/list',
        name: 'MerchantApplyList',
        component: () => import('@/views/merchant/apply/list.vue'),
        meta: { name: '商户入驻' }
      },
      {
        path: '/merchant/withdraw/list',
        name: 'MerchantWithdrawList',
        component: () => import('@/views/merchant/withdraw/list.vue'),
        meta: { name: '商户提现' }
      }
    ]
  },

  systemManageRouter
]
